import 'vite/modulepreload-polyfill';
import '@tabler/core/src/js/tabler.js';
import 'htmx.org';
import * as CookieConsent from "vanilla-cookieconsent";

import '../js/main.js';
import '../js/common/themePage.js';
import '../js/algolia/app.js';
import '../js/common/notifications.js';
import initMessageCounters from './form/textArea.ts';
import phoneNumberInput from "../js/widgets/phoneField.js";

// @ts-ignore
import white_logo_tagline from '../icon/en/logo/white_logo_tagline.png'
import {Notyf} from "notyf";

// @ts-ignore
import Alpine from "alpinejs";

// @ts-ignore
window.Alpine = Alpine;
Alpine.start();


CookieConsent.run({
    categories: {
      necessary: {
        enabled: true,  // this category is enabled by default
        readOnly: true  // this category cannot be disabled
      },
      analytics: {}
      // Add more categories here (e.g., marketing) if you need them
    },
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom left',
            flipButtons: false,
            equalWeightButtons: true
        },
        preferencesModal: {
            layout: 'box',
            // position: 'left right',
            flipButtons: false,
            equalWeightButtons: true
        }
    },
    language: {
      default: 'en',
      translations: {
        en: {
          consentModal: {
            title: 'Your Privacy Matters',
            description: 'We use cookies to provide you with the best possible experience on our website, understand usage, and display relevant content. Click “Accept all” to consent, or “Reject all” if you prefer to disable nonessential cookies.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage preferences'
          },
          preferencesModal: {
            title: 'Cookie Settings',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Accept current selection',
            closeIconLabel: 'Close window',
            sections: [
              {
                title: 'Why Cookies?',
                description: 'Cookies help us improve our platform, personalize content, and remember your preferences.'
              },
              {
                title: 'Strictly Necessary Cookies',
                description: 'These cookies are essential for the website to function properly and cannot be switched off.',
                linkedCategory: 'necessary'
              },
              {
                title: 'Analytics Cookies',
                description: 'These cookies help us analyze site traffic and usage so we can measure and improve performance.',
                linkedCategory: 'analytics'
              },
            ]
          }
        }
      }
    }
  });

export const notyf = new Notyf(
    {
        duration: 0,
        dismissible: true,
        position: {
            x: 'right',
            y: 'top',
        },
        types: [
            {
                type: 'success',
                duration: 6000,
                background: '#2fb344',
                icon: {
                    className: "ti ti-circle-check-filled tb-alert-i",
                    tagName: "i",
                    color: "white",
                }
            },
            {
                type: 'info',
                background: '#4299e1',
                duration: 6000,
                icon: {
                    className: "ti ti-info-circle-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            },
            {
                type: 'warning',
                background: '#f76707',
                icon: {
                    className: "ti ti-alert-circle-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            },
            {
                type: 'error',
                background: '#d63939',
                icon: {
                    className: "ti ti-xbox-x-filled tb-alert-i",
                    tagName: "i",
                    color: "white"
                }
            }
        ]
    }
);

// @ts-ignore
(window as any).notyf = notyf;

const brandLogo = document.getElementById('navbar-brand-image') as HTMLImageElement | null;
if (brandLogo && !brandLogo.src) {
    brandLogo.src = white_logo_tagline;
}

function initPhoneField() {
    document.querySelectorAll<HTMLTextAreaElement>('input.textarea-with-mobile-number').forEach((input: HTMLTextAreaElement) => {
        const formId = input.form?.id;

        if (!formId) {
            console.error('Form id not found');
            return;
        }

        const sbmtBtn = document.querySelector(`button[type="submit"][form="${formId}"]`) as HTMLButtonElement;
        phoneNumberInput(input.id, sbmtBtn, true);
    });
}

document.addEventListener('DOMContentLoaded', () => {
  initMessageCounters();
  initPhoneField();
});

document.body.addEventListener("htmx:afterSwap", () => {
    initMessageCounters();
    // TODO: Fix frontend mistake when re-init the phone widget
    initPhoneField();
});
